import * as React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeButton from "../../components/ThemeButton";

import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import DestinoFeriasLogo from "../../assets/img/logo_df_vertical.png";
import FormInput from "../../components/FormInput";

import * as S from "../../components/LoginComponents/styles";
import { useGlobal } from "../../contexts/globalContext";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { auth } from "../../services/firebase";
import { useQuery } from "../../hooks/useQuery";
import { useCampaign } from "../../hooks/useCampaign";
import Whatsapp from "../../assets/icons/Whatsapp.svg";

const LoginLogo = ({ campaignId, ext }) => {
	if (campaignId) {
		return <S.Logo src={`/img/campaign/${campaignId}/logo_login.jpg?ext=${ext}`} alt="Destino Ferias" style={{ width: "200px" }} />;
	}

	return <S.Logo src={DestinoFeriasLogo} alt="Destino Ferias" />;
};

const getLoginBackground = (campaignId, ext) => {
	if (campaignId) {
		return {
			backgroundImage: `url(/img/campaign/${campaignId}/bg_login.jpg?ext=${ext})`,
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover",
			backgroundPosition: "center",
		};
	}

	return {
		backgroundImage: `url(/img/bg_login.jpg?ext=${ext})`,
		backgroundRepeat: "no-repeat",
		// backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
		backgroundSize: "cover",
		backgroundPosition: "center",
	};
};

export default function Login() {
	const { loginFirebase, setLoading, showAlert, loginGuestByCampaignCode, signed } = useGlobal();
	const code = useQuery().get("code");

	const [recoverPass, setRecoverPass] = React.useState(false);
	const [user, setUser] = React.useState("");
	const [pass, setPass] = React.useState("");
	const [error, setError] = React.useState("");
	const [loadingConfig, setLoadingConfig] = React.useState(true);
	const [ext, setExt] = React.useState(null);

	const { campaignId, campaignCode, getCampaignColors } = useCampaign();

	let history = useHistory();

	const handleSubmit = (event) => {
		setLoading(true);
		setError("");
		try {
			if (recoverPass) {
				if (user !== "") {
					auth
						.sendPasswordResetEmail(user)
						.then(() => {
							showAlert("success", "Enviamos um e-mail para você atualizar sua senha, verifique!");
							setRecoverPass(false);
						})
						.catch((e) => {
							setError("Por favor, digite um e-mail valido");
						});

					setLoading(false);
				} else setError("Por favor, digite um e-mail valido");
			} else {
				if (user !== "" && pass !== "") {
					loginFirebase(user, pass).then((result) => {
						if (!result) {
							setError("Usuário ou senha inválidos");
							setLoading(false);
						} else {
							//history.push("/");
							setLoading(false);
							window.location.reload();
						}
					});
				} else {
					setError("Por favor, digite um usuário e senha");
					setLoading(false);
				}
			}
		} catch (e) {
			setError(e);
			setLoading(false);
		}
	};

	React.useEffect(() => {
		const loadConfig = async () => {
			await getCampaignColors(true);
			setLoadingConfig(false);
		};
		//if (campaignId && campaignCode)
		loadConfig();
	}, [campaignId, campaignCode, getCampaignColors]);

	React.useEffect(() => {
		if (signed) {
			history.push("/");
		}
	}, [signed, history]);

	React.useEffect(() => {
		if (code) {
			loginGuestByCampaignCode(code);
		}
	}, [code, loginGuestByCampaignCode]);

	React.useEffect(() => {
		setExt(new Date().getTime());
	}, []);

	return (
		<>
			{!loadingConfig && (
				<Grid container component="main" style={{ height: "100vh" }}>
					<CssBaseline />
					<Grid
						item
						xs={false}
						sm={4}
						md={7}
						style={{
							...getLoginBackground(campaignId, ext),
						}}
					/>
					<Grid item xs={12} sm={8} md={5} component={Paper} elevation={10} square>
						<Box
							sx={{
								my: 8,
								mx: 4,
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<LoginLogo campaignId={campaignId} ext={ext} />
							{recoverPass && (
								<S.TitleLogin variant="h6" align="center">
									Não consegue entrar
								</S.TitleLogin>
							)}
							<S.TitleLogin variant="body2" align="center">
								{recoverPass && "Enviaremos um link de recuperação para o seu e-mail"}
							</S.TitleLogin>

							<Box sx={{ mt: 3 }} style={{ width: "100%" }}>
								<FormInput
									margin="normal"
									required
									fullWidth
									label="Digite seu E-mail"
									autoFocus
									value={user}
									onChange={(e) => setUser(e.target.value)}
									onKeyPress={(e) => {
										if (recoverPass && e.key === "Enter") {
											handleSubmit();
										}
									}}
								/>
								{!recoverPass && (
									<FormInput
										margin="normal"
										required
										fullWidth
										label="Senha"
										type="password"
										value={pass}
										onChange={(e) => setPass(e.target.value)}
										onKeyPress={(e) => {
											if (e.key === "Enter") {
												handleSubmit();
											}
										}}
									/>
								)}

								{error && (
									<Typography color="error" variant="body2">
										{error}
									</Typography>
								)}
								<ThemeButton fullWidth onClick={handleSubmit} className="login">
									{recoverPass ? "Recuperar" : "Login"}
								</ThemeButton>
								<Grid container>
									<Grid item xs={12} style={{ marginBottom: "1em" }}>
										<S.ForgotPass
											onClick={() => {
												setError("");
												setRecoverPass(!recoverPass);
											}}
										>
											{recoverPass ? "Voltar" : "Esqueceu sua senha?"}
										</S.ForgotPass>
									</Grid>
									{process.env.REACT_APP_GUEST_LOGIN?.toLowerCase() === "true" && (
										<Grid item xs={12} style={{ marginBottom: "1.5em" }}>
											<S.LoginWithoutRegister
												onClick={() => {
													history.push("/guest");
												}}
											>
												Ainda não tem cadastro? <b>Clique e confira as vantagens.</b>
											</S.LoginWithoutRegister>
										</Grid>
									)}
									{process.env.REACT_APP_WHATSAPP_LINK && (
										<div>
											<Grid item xs={12}>
												<S.CallToContactAction
													onClick={() => {
														window.open(process.env.REACT_APP_WHATSAPP_LINK, "_blank");
													}}
												>
													Dúvidas? Fale conosco.
												</S.CallToContactAction>
											</Grid>
											<Grid item xs={12} style={{ display: "flex", alignItems: "center", marginTop: "7px" }}>
												<S.WhatsappIcon
													src={Whatsapp}
													alt="Whatsapp"
													onClick={() => window.open(process.env.REACT_APP_WHATSAPP_LINK, "_blank")}
												/>
												<S.CallToContactAction
													onClick={() => {
														window.open(process.env.REACT_APP_WHATSAPP_LINK, "_blank");
													}}
												>
													{process.env.REACT_APP_WHATSAPP_NUMBER ? process.env.REACT_APP_WHATSAPP_NUMBER : "Clique aqui!"}
												</S.CallToContactAction>
											</Grid>
										</div>
									)}
								</Grid>
							</Box>
						</Box>
					</Grid>
				</Grid>
			)}
		</>
	);
}
